import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { Link } from 'gatsby';
import i18n, {locale} from '../../../../locale';
import Typography from '../../../../components/Typography';
import Next from '../Next';
import styles from './styles.module.sass';
import Clients from '../Clients'
import ErrorMessage from '../ErrorMessage';

export default ({
  email,
  phone,
  name,
  regNumber,
  setEmail,
  setPhone,
  setName,
  setRegNumber,
  onNext,
  error
}) => (
  <Grid container className={ locale==='en' ? styles.wrapper : styles.daWrapper}>
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={clsx(styles.pricingColumn, styles.columnLeft)}>
      <Typography className={styles.title}
        variant='h2'
        weight='bold'
        align='left'>
        {i18n('Fill out the form')}
      </Typography>
      <Typography className={styles.infoText}
        variant='subtitle1'
        align={'left'}>
        {i18n('As soon as we have received your information, we will create an account in Fleet for you. You can get started right away.')}
      </Typography>
      <br/>
      <Typography className={clsx(styles.infoText, styles.lastItem)}
        variant='subtitle1'
        align={'left'}>
        {i18n('We will contact you soon to answer any questions and make sure that you have a great start.')}
      </Typography>

      <Typography className={styles.title}
        variant='h2'
        weight='bold'
        align='left'>
        {i18n('Try for free in 30 days')}
      </Typography>
      <Typography className={clsx(styles.infoText, styles.lastItem)}
        variant='subtitle1'
        align={'left'}>
        {i18n('When you have submitted the form and we have received your information, you can test the service for free.')}
      </Typography>

      <Typography className={styles.title}
        variant='h2'
        weight='bold'
        align='left'>
        {i18n('End of the test period')}
      </Typography>
      <Typography className={clsx(styles.infoText, styles.lastItem)}
        variant='subtitle1'
        align={'left'}>
        {i18n('When your test period finishes, we will contact you to discuss how you want to use Fleet in the future.')}
      </Typography>
    </Grid>
    
    <Grid item xs={12} sm={12} md={locale === 'en' ? 6 : 12} lg={6} xl={6} className={clsx(styles.pricingColumn, styles.columnRight)}>
      {/* <div className={styles.container}>
        <input type='text' className={styles.input}
          defaultValue={regNumber}
          onChange={e => setRegNumber(e.target.value)}
          placeholder={i18n('Company reg. no.')}/>
      </div> */}
      <div className={styles.container}>
        <input type='email' className={styles.input}
          defaultValue={email}
          onChange={e => setEmail(e.target.value)}
          placeholder={i18n('Email address')}/>
      </div>
      <div className={clsx(
          styles.container,
          styles.phoneContainer
        )}>
        <input type='text' className={styles.input}
          defaultValue={name}
          onChange={e => setName(e.target.value)}
          placeholder={i18n('Name')}/>
      </div>
      <div className={clsx(
          styles.container,
          styles.nameContainer
        )}>
        <input type='tel' className={styles.input}
          defaultValue={phone}
          onChange={e => setPhone(e.target.value)}
          placeholder={i18n('Telephone number (optional)')}/>
      </div>
      <Typography className={styles.termsContainer}
        variant='subtitle1'
        weight='light'
        color='dark'>
        {i18n('By clicking \'Send me an offer\', you consent to OBI Plus ApS storing and processing the personal information you have provided above to deliver what you have requested.')}
        <br/>
        <br/>
        {i18n('You can unsubscribe from these messages at any time. For more information on how to unsubscribe, about our practices, and how we are committed to protecting and respecting your privacy, read our')}&nbsp;
        <Link to='/privacy-policy'
          target='blank'
          className={styles.anchor}>
          {i18n('Terms of Use and Privacy Policy')}
        </Link>.
      </Typography>
      <Next disabled={!email || !name}
        text={i18n('Send')}
        onClick={onNext}/>
      <ErrorMessage error={error}/>
    </Grid>
    <Clients/>
  </Grid>
)