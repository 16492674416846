import React from 'react';
import i18n from '../../../../locale';
import QuestionTitle from '../QuestionTitle';
import styles from './styles.module.sass';

export default () => (
  <div className={styles.container}>
    <QuestionTitle
      text={i18n('Our sales team is currently preparing the offer for you.')}/>
  </div>
)